import { template as template_84f590884c554fecb8da85bb833cb71d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_84f590884c554fecb8da85bb833cb71d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
