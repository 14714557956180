import { template as template_b9d9f09609ce46d58cd41be87494ad62 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_b9d9f09609ce46d58cd41be87494ad62(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
