import { template as template_05be0745d4e44deebe1716aa5e055f92 } from "@ember/template-compiler";
const FKControlMenuContainer = template_05be0745d4e44deebe1716aa5e055f92(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
